:root {
  --background-color: white;
  --text: #454545;
  // --text: #e5e5e5;
  --primary: #3565FF;
  --dark-primary: #1439B3;
  --light-primary: #4F78FF;
  --light: #FFC836;
  --accent: #B38302;
  --border: #ccc;
  // --piping: #555;
  --piping: #ddd;
  --light-gray: #777;
  // --gray: #454545;
  --gray: #333;
  --dark-gray: #232323;
  // --background-color: var(--gray);
}

* {
  box-sizing: border-box;
}

body, html {
  min-height: 100vh;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: Roboto, San Francisco, Helvetica Neue, sans-serif;
  color: var(--text);
  background-color: var(--background-color);
}

#app-root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

#app-main {
  padding: 8px 16px;
  position: relative;
  flex-grow: 2;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &.action {
    color: var(--accent);
  }
}

#footer {
  color: var(--light-primary);
  bottom: 0;
  width: 100%;
  padding: 12px 16px 24px 16px;
  margin: -4px 0 0 0;
  // border-radius: 12px 12px 0 0;
  border-top: 1px solid var(--light-primary);
  background-color: var(--dark-primary);
  // box-shadow: -6px 0px 12px #000;
}

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  // box-shadow: 0 4px 5px #eee;
  // background-color: var(--light-gray);

  h3 {
    color: var(--primary);
  }
}

@import './main.scss';
@import './form.scss';