.prayer-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid var(--piping);
  // background-color: var(--gray);
  border-radius: 0;
  // box-shadow: 0px -6px 6px #e5e5e5; 

  li {
    padding: 24px 8px;
    border-bottom: 1px solid var(--piping);

    &:last-of-type {
      border: none;
    }
  }

  .prayer-date {
    color: #aaa;
    font-size: 12px;
  }
}


